import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import "./component.css";

// Raido button
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

/***************************************
 * library and worker to read pdf files
 */
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import { getDocument } from "pdfjs-dist/legacy/build/pdf";
import parseGrantProposalText from "../utils/parseGrantProposalText";
const pdfjsWorker = require("pdfjs-dist/legacy/build/pdf.worker.entry");
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// *************************************

const baseurl = process.env.REACT_APP_API_URL;
// const baseurl = "https://app.grantaide.com/";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButton = styled(Button)({
  height: "40px",
  color: "white",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#380093",
  border: "solid 3px #1B4595",
});

const modalStyle = {

  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const customStyle = {

  backgroundColor: "#18003F",
  color: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1B4595",
    },
    "&:hover fieldset": {
      borderColor: "#1B4595",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1B4595",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white", // Added to change the text color inside the input
  },
};

const menuProps = {
  PaperProps: {
    sx: {
      backgroundColor: "#18003F",
      color: "white",
      "& .MuiMenuItem-root": {
        "&:hover": {
          backgroundColor: "#1B4595",
        },
        "&.Mui-selected": {
          backgroundColor: "#1B4595",
          "&:hover": {
            backgroundColor: "#1B4595",
          },
        },
      },
    },
  },
};

const FileUploader = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [documentType, setDocumentType] = useState("grantproposal");
  const [documentDescription, setDocumentDescription] = useState("");
  const { from, uid, sendDataToParent } = props;

  useEffect(() => {
    if (props.from === "grantaide") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const handleClose = () => setOpen(false);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const file = files[0];
    if (files.length === 0) return;

    // Update state with new files if you want to keep track of them
    setUploadedFiles((currentFiles) => [...currentFiles, ...files]);

    /** set up reader to read content of uploaded file */
    const reader = new FileReader();
    if (file.type === "application/pdf") {
      reader.onload = async (e) => {
        const typedArray = new Uint8Array(e.target.result);
        try {
          const pdfDoc = await getDocument(typedArray).promise;
          console.log(`PDF loaded: ${pdfDoc.numPages} page(s) found.`);
          let combinedText = "";

          const numPages = pdfDoc.numPages;

          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const page = await pdfDoc.getPage(pageNum); // Get page by current pageNum
            const textContent = await page.getTextContent(); // Get text content of the page
            const textItems = textContent.items
              .map((item) => item.str)
              .join(" "); // Join text items into a single string
            // console.log(`Page ${pageNum}: ${textItems}`); // Log the text content of the current page

            combinedText += textItems + " ";
          }

          /** send text to openAI for extracting information */
          let response = await parseGrantProposalText(combinedText);
          sendDataToParent(response);
          if (!response) {
            throw new Error("Could not extract grant proposal text");
          }
        } catch (error) {
          console.log("Error reading PDF:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      reader.onload = (e) => {
        const content = e.target.result;
        console.log(content);
      };
      reader.onerror = (e) => {
        console.log("error reading file:", e.target.error);
      };
      reader.readAsText(file);
    }

    /**
     * set up file ready to be uploaded to GCS
     * */
    const formData = new FormData();
    // Append each file into formData; and uid
    Array.from(files).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("uid", uid);

    // Upload file to server
    try {
      const response = await fetch(`${baseurl}/gptRun/upload`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("File uploaded successfully: ", data);
      } else {
        console.error("Failed to upload file: ", data);
      }
    } catch (err) {
      console.error("Error uploading file: ", err);
    }
  };

  const handleAddFiles = () => {
    // Trigger the file selection using the hidden input element
    document.getElementById("upload-button").click();
    setOpen(false);
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleDocumentDescriptionChange = (event) => {
    setDocumentDescription(event.target.value);
  };

  return (
    <div className="sidemenu w-auto h-5/6 mt-6 rounded-md mx-2">
      <label htmlFor="upload-button">
        <UploadButton
          type="button"
          className="upload-btn"
          variant="contained"
          component="span"
        >
          <div className="upload-btn-divs text-sm sm:w-auto lg:w-[150px]">Upload Files</div>
          <AddCircleOutlineIcon />
        </UploadButton>
      </label>
      <VisuallyHiddenInput
        id="upload-button"
        type="file"
        multiple
        accept=".docx,.pdf,.txt"
        onChange={handleFileChange}
      />
      {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded-file">
          <InsertDriveFileOutlinedIcon />
          {file.name.length > 35 ? `${file.name.slice(0, 35)}...` : file.name}
        </div>
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            id="modal-title"
            variant="h6"
            component="h2"
            color="white"
          >
            Document Upload
          </Typography>
          {/* Added padding of two to the x coordinates  */}
          <FormControl fullWidth sx={{ mt: 2, paddingX: 2, ...customStyle }}>
            <RadioGroup
              aria-labelledby="document-type-label"
              value={documentType}
              onChange={handleDocumentTypeChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="grantproposal"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Grant Proposal"
              />
              <FormControlLabel
                value="grantqna"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="(RFP) Request for Proposal"
              />
              <FormControlLabel
                value="grantNofo"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="(NOFO) Notice of Funding Opportunity"
              />
              <FormControlLabel
                value="grantFinancialDoc"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Financial Document"
              />
              <FormControlLabel
                value="other"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            id="document-description"
            label="Document Description"
            multiline
            rows={4}
            value={documentDescription}
            onChange={handleDocumentDescriptionChange}
            variant="outlined"
            fullWidth
            sx={{ mt: 2, ...customStyle }}
          />
          <div className="file-upload-btns">
            <Button
              onClick={handleAddFiles}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Add files
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FileUploader;
